.NoStatisticsMessage {
  background-color: rgba(138, 155, 168, 0.10);
  p {
    font-size: 15px;
    text-align: center;
    margin: 10px;
    margin: 4px 0;
    border-radius: 0px;
    font-style: italic;
    color: #afafaf; } }
