.Profile {
  width: 100%;
  max-width: 900px;
  margin: auto;
  .profile-card {
    h5 {
      margin-top: 0; }
    .bp3-form-group {
      max-width: 500px; }
    .error-message, .success-message {
      margin-bottom: 15px;
      p {
        margin-bottom: 0; } } } }
