.Provisions {
  .wallet-informations {
    margin-top: 15px;
    margin-bottom: 25px;
    text-align: right;

    .saldo {
      font-size: 35px;
      color: #005896; }

    .next-payment {
      color: #5e5e5e;
      font-size: 13px;
      font-weight: 600; } } }
