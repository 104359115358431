.ToggleButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  line-height: 67px;
  text-align: center;
  z-index: 18;
  &:hover {
    background-color: #f1f1f1;
    cursor: pointer; } }
