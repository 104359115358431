tr.DamageRequestRow {
  line-height: 28px;

  td:last-child {
    text-align: right !important; }

  span.state-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 50%; } }

.message-wrapper {
  white-space: pre-wrap;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; }
