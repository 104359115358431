@import '~normalize.css';
@import '~flexboxgrid';

@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';

@import '~semantic-ui-css/components/table.css';

html, body, #root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9; }
