.BoatData {
  table {
    width: 100%;
    tbody td {
      border-bottom: 1px solid #ddd; } }
  .row:not(:last-child) {
    margin-bottom: 30px; }
  .row:last-child {
    table tbody tr:last-child td {
      border-bottom: none; } }
  .col-xs-12, .col-sm-6 {
    padding-right: 0;
    padding-left: 0; }
  img {
    width: 100%; } }
