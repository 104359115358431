.MyDataRow {
  td {
    border-bottom: 1px solid #ddd;
    line-height: 28px;
    &:first-child {
      width: 300px; }
    &.actions {
      text-align: right;
      button {
        text-align: center; } }
    &.not-available {
      color: #bbb;
      font-style: italic;
      font-size: 9pt;
      padding-top: 12px; }
    input {
      margin-top: 1px;
      margin-bottom: -2px; }
    .bp3-form-group {
      margin-bottom: 0; }
    .bp3-form-helper-text {
      height: 18px;
      line-height: 18px; } } }
