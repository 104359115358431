.ContractDetails {
  width: 100%;
  max-width: 900px;
  margin: auto;
  margin-top: -15px;
  padding-bottom: 50px;
  table {
    width: 100%;
    tbody {
      tr:not(:last-child) {
        td {
          border-bottom: 1px solid #ddd; } } } } }
