.ProposalDetails {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: auto;
  margin-top: -15px;
  padding-bottom: 50px;
  & > a {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    button {
      margin-right: 0; } }
  table {
    width: 100%;
    tbody {
      tr:not(:last-child) {
        td {
          border-bottom: 1px solid #ddd; } } } }

  table.proposal-documents {
    margin-bottom: 30px;
    tbody {
      td {
        border-bottom: 1px solid #ddd; }
      tr:first-child {
        td {
          border-top: none; } } } }

  .row:not(:last-child) {
    margin-bottom: 30px; }
  .row:last-child {
    table tbody tr:last-child td {
      border-bottom: none; } }
  .col-xs-12, .col-sm-6 {
    padding-right: 0;
    padding-left: 0; } }
