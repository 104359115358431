.Websites {
  width: 100%;
  margin-top: -15px;
  border: none !important;
  thead, tbody {
    th, td {
      border-bottom: 1px solid #ddd;
      &:nth-child(2) {
        width: 540px; } } }
  tr {
    line-height: 28px;
    td:last-child {
      width: 100px;
      text-align: right; }
    &:first-child td {
      border-top: 2px solid #ddd; }
    &:last-child td {
      border-bottom: 2px solid #ddd; } } }
