.WebsiteRow {
  td {
    border-bottom: none !important; } }
.AccessRow {
  td {
    border-top: none !important;
    border-bottom: none !important;
    padding-top: 0 !important;
    &.WebsiteAccessId {
      text-align: left !important;
      p > code, {
        display: inline-block;
        font-size: 9pt;
        color: #c7254e;
        background-color: #f9f2f4;
        white-space: nowrap;
        border-radius: 4px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 5px; }
      pre {
        border-radius: 4px;
        word-break: break-all;
        word-wrap: break-word;
        code {
          white-space: pre-wrap; } } } } }
.DefaultBoatTypeRow {
  td {
    border-top: none !important;
    padding-top: 0 !important;
    text-align: left !important; } }
