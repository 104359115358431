.AttachmentsTable {
  margin-top: 15px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  td:hover {
    background-color: #fbfbfb; }
  tr:not(:last-child) {
    td {
      border-bottom: 1px solid #eee; } } }
