#app-wrapper {
  width: 100%;
  height: calc(100% - 50px);
  position: absolute;
  top: 50px;
  left: 0;
  padding: 30px;
  padding-left: 230px;
  transition: padding-left .3s;
  @media (max-width: 1000px) {
    padding: 15px; }
  #app-content {
    position: relative;
    width: 100%;
    min-height: 100%; } }
