.ProvisionsTable {
  width: 100%;
  border: none !important;
  tbody {
    td p {
      margin-bottom: 0;
      white-space: pre-wrap; }
    td:last-child {
      text-align: right; }
    .green-sign {
      color: #49a603;
      font-size: 20px;
      font-weight: 900; }
    .red-sign {
      color: #f00;
      font-size: 20px;
      font-weight: 900; } } }
