aside.Sidebar {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 5px 0 5px -5px rgba(16, 22, 26, 0.3);
  z-index: 19;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 200px;
  height: 100%;
  transition: left .3s;
  left: -200px;
  &.sidebar-open {
    left: 0px; }
  .sidebar-header {
    height: 50px;
    width: 200px;
    .logo {
      margin-top: 5px;
      margin-right: 5px;
      float: right;
      height: 40px;
      width: 130px;
      background-image: url("../assets/nammert-partner-logo.png");
      background-size: contain;
      background-repeat: no-repeat; } }
  ul {
    width: 100%;
    margin: 0;
    padding-left: 0;
    list-style: none;
    li {
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: right;
      &:hover {
        background-color: #f6f6f6;
        cursor: pointer; }
      &.active {
        background-color: #f1f1f1; }
      & > a {
        display: block;
        width: 100%;
        height: 100%;
        color: #555;
        text-decoration: none; }
      .bp3-icon {
        float: right;
        width: 25px;
        margin-top: 16px;
        margin-left: 10px;
        margin-right: 5px;
        text-align: center; } }
    &.bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      li {
        text-align: left;
        .bp3-icon {
          float: left;
          width: 25px;
          margin-top: 16px;
          margin-left: 10px;
          margin-right: 5px;
          text-align: center;
          &.bp3-icon-log-out {
            transform: rotate(180deg);
            margin-left: 2px;
            margin-right: 12px; } } } } } }
