.DocumentsTable {
  &:not(:last-child) {
    table {
      margin-bottom: 30px;
      tbody {
        td {
          border-bottom: 1px solid #ddd; } } } }
  table {
    .bp3-text-overflow-ellipsis {
      white-space: pre-line !important; } }
  thead {
    th:last-child {
      text-align: right;
      &>span {
        margin-left: 3px;
        margin-bottom: 3px; } } } }
