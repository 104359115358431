.LoadingIndicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 18;
  width: 100%;
  text-align: center;
  background-color: rgba(#fff, .9);
  color: #333;
  .bp3-spinner-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: -25px;
    margin-top: -25px; } }
