.OfferedProposals {
  .button-wrapper {
    width: 100%;
    text-align: right;
    margin-bottom: 15px;
    button {
      margin-right: 0; } }
  .empty-message {
    background-color: rgba(138, 155, 168, 0.10);
    p {
      font-size: 15px;
      text-align: center;
      margin: 10px;
      margin: 4px 0;
      border-radius: 0px;
      font-style: italic;
      color: #afafaf; } } }
