.FilesUploader {
  table {
    margin: 10px 0;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    tr:hover {
      background-color: #fbfbfb; }
    tr:not(:last-child) {
      td {
        border-bottom: 1px solid #eee; } }
    td {
      line-height: 22px;
      &:last-child {
        text-align: right; } } }

  input#file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    & + label {
      width: 100%; } } }
